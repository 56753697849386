import "./LandingHelp.scss"

export default function LandingHelp() {
    return (
        <section className="LandingHelp" id="help">
            <div className="wrap">
                <div className="help-faq">
                    <h2>Частые вопросы</h2>
                    <div className="faq-item">
                        <span>Как заказать такси?</span>
                        <p>
                            Достаточно позвонить нам по телефону <a href="tel:+79507007071">8-(950)-700-70-71</a> или же написать напрямую в <a href="https://t.me/taxi036" target="_blank" rel="noreferrer">Telegram</a> или <a href="https://wa.me/79507007071" target="_blank" rel="noreferrer">Whatsapp</a><br />
                            <br />
                            Наш менеджер уточнит детали поездки и подберёт вам нужную машину.
                        </p>
                    </div>
                    <div className="faq-item">
                        <span>Как узнать цену поездки?</span>
                        <p>
                            На сайте есть калькулятор, который рассчитывает цену поездки.<br />
                            В поле «Откуда» введите название города - место отправления, в поле «Куда» - место назначения.
                            Можно так же выбрать населенный пункт из выпадающих подсказок.<br />
                            <br />
                            Далее нажмите кнопку «Узнать цену».
                        </p>
                    </div>
                    <div className="faq-item">
                        <span>Как я найду своего водителя?</span>
                        <p>
                            Как только менеджер подберёт Вам машину, он сообщит детали и свяжет Вас с водителем.
                        </p>
                    </div>
                </div>
                <div className="help">
                    <h2>Помощь</h2>
                    <span>По любым вопросам</span>
                    <div className="links">
                        <div className="link">
                            <svg className="" xmlns="http://www.w3.org/2000/svg" width="20" height="16" fill="none" viewBox="0 0 20 16">
                                <path fill="#805400" fillOpacity=".4" fillRule="evenodd" d="M20 2c0-1.1-.9-2-2-2H2C.9 0 0 .9 0 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V2zm-2 0l-8 5-8-5h16zm-8 7L2 4v10h16V4l-8 5z" clipRule="evenodd" />
                            </svg>
                            <a href="mailto:help@fleeka.ru">help@fleeka.ru</a>
                        </div>
                        <div className="link">
                            <svg className="" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
                                <path fill="#805400" fillOpacity=".4" fillRule="evenodd" d="M14 0H1C.45 0 0 .45 0 1v14l4-4h10c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1zm-1 2v7H3.17L2 10.17V2h11zm4 2h2c.55 0 1 .45 1 1v15l-4-4H5c-.55 0-1-.45-1-1v-2h13V4z" clipRule="evenodd"></path>
                            </svg>
                            <a href="https://t.me/taxi036" target="_blank" rel="noreferrer">
                                Написать в телеграм
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}